
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/products/test-products",
      function () {
        return require("private-next-pages/products/test-products.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/products/test-products"])
      });
    }
  