const categoriesDataV2 = {
  cards: [
    {
      key: "ibmTopics",
      value: "cat.topic:Analytics"
    },
    {
      key: "ibmTopics",
      value: "cat.topic:Blockchain"
    },
    {
      key: "ibmTopics",
      value: "cat.topic:BusinessOperations"
    },
    {
      key: "ibmTopics",
      value: "cat.topic:CloudComputing"
    },
    {
      key: "ibmTopics",
      value: "cat.topic:ConsultingServices"
    },
    {
      key: "ibmTopics",
      value: "cat.topic:Cybersecurity"
    },
    {
      key: "ibmTopics",
      value: "cat.topic:ITInfrastructure"
    },
    {
      key: "ibmTopics",
      value: "cat.topic:Industries"
    },
    {
      key: "ibmTopics",
      value: "cat.topic:SupplyChain"
    }
  ]
};

export default categoriesDataV2;
